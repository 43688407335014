import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FacebookLogin from 'react-facebook-login';

import Cookies from 'js-cookie';
import * as constants from './Constants.js';

function createObjectURL(object) {
  return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
}

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.eventId = this.props.match.params.eventId;
    this.name = '';

    this.state = {
      eventName: '',
      imageUrl: '',
      isNonFacebookRegister: false,
      showAlert: false,
      alertVariant: '',
      alertMessage: ''
    };

    fetch(constants.API_ADDRESS + '/event?id=' + this.eventId, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      if (response.status == 'ok') {
        this.setState({eventName: response.event.name});
      }
    });

    this.responseFacebook = this.responseFacebook.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.uploadToS3 = this.uploadToS3.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.detectEnterSubmit = this.detectEnterSubmit.bind(this);
    this.getFileInput = this.getFileInput.bind(this);
    this.fileInputChanged = this.fileInputChanged.bind(this);
  }

  componentDidMount() {
    document.getElementById("fileInput").onchange = this.fileInputChanged;
  }

  async responseFacebook(response) {
    const accessToken = response.accessToken;
    const name = response.name;
    if (name != '' && name != null) {
      Cookies.set(this.eventId + ':name', name);
      this.fetchLargePicture(accessToken, (imageUrl) => {
        this.createAttendee(this.eventId, name, imageUrl, () => {
          window.location.href = '/event/' + this.eventId;
        })
      });
    }
  }

  async fetchLargePicture(accessToken, successCallback) {
    fetch('https://graph.facebook.com/me/picture?redirect=false&type=large&access_token=' + accessToken, {
      method: 'GET'
    }).then(response => response.json())
    .then(response => {
      successCallback(response.data.url);
    });
  }

  getFileInput() {
    var files = document.getElementById("fileInput").files;
    if (files.length == 0) {
      return null;
    }
    var file = files[0];
    if(!file){
      return null;
    }
    return file;
  }

  fileInputChanged() {
    var file = this.getFileInput();
    var src = createObjectURL(file);
    this.setState({imageUrl: src});
  }

  onUsernameChange(event) {
    this.name = event.target.value;
  }

  detectEnterSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onRegister();
    }
  }

  onRegister() {
    if (this.name === '') {
      this.setState({showAlert: true, alertVariant: 'danger', alertMessage: 'Empty name'});
      return;
    }

    const imageFile = this.getFileInput();
    if (imageFile == null) {
      this.createAttendee(this.eventId, this.name, 'https://static-00.iconduck.com/assets.00/profile-circled-icon-512x512-vcuphqif.png', () => {window.location.href = '/event/' + this.eventId});
    } else {
      this.uploadToS3(imageFile, (imageUrl) => {
        this.createAttendee(this.eventId, this.name, imageUrl, () => {window.location.href = '/event/' + this.eventId});
      });
    }
  }

  createAttendee(eventId, attendeeName, attendeeImageUrl, successCallback) {
    Cookies.set(eventId + ':name', attendeeName);
    Cookies.set(this.eventId + ':picture', attendeeImageUrl);
    fetch(constants.API_ADDRESS + '/attendee', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({})
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
        Cookies.set(this.eventId + ':id', response.attendee_id);
        successCallback();
      }
    });
  }

  uploadToS3(imageFile, successCallback) {
    fetch(constants.API_ADDRESS + '/s3_sign?file_type=' + imageFile.type, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log('got signed request', response);
      if (response.status == 'error') {
        this.setState({isCurrentlySubmitting: false, showAlert: true, alertMessage: 'Submission error.', alertVariant: 'danger'});
        return;
      }
      const formData = new FormData()
      for(var key in response.presigned.fields){
        formData.append(key, response.presigned.fields[key]);
      }
      var uploadedUrl = response.url;
      formData.append('file', imageFile);
      fetch(constants.S3_ADDRESS, {
        method: 'POST',
        crossDomain: true,
        body: formData
      }).then(response => {
        this.setState({isCurrentlySubmitting: false});
        successCallback(uploadedUrl);
      });
    });
  }

  render() {
    const verticalAlign = {
      'display': 'flex',
      'align-items': 'center'
    };

    return (
      <div>
        <Row>
          <Col md={12}>
            <h1 className='text-center'>Join the event - {this.state.eventName}!</h1>
          </Col>
        </Row>
        <br/>
        <div className={(this.state.isNonFacebookRegister ? 'd-none' : 'd-block')}>
          <Row>
            <Col md={12} className='text-center'>
              <FacebookLogin
                appId="1374807946597667"
                autoLoad={true}
                fields="name,picture"
                textButton="Join with Facebook"
                callback={this.responseFacebook} />
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md={12} className='text-center'>
              <h3>or</h3>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md={4} />
            <Col md={4}>
              <Button className='btn btn-block btn-lg btn-secondary' onClick={() => this.setState({isNonFacebookRegister: true})}>I don't have Facebook</Button>
            </Col>
          </Row>
        </div>
        <div className={(this.state.isNonFacebookRegister ? 'd-block' : 'd-none')}>
          <Row>
            <Col md={3} />
            <Col md={6} className='text-center'>
              <input type="text" class="form-control form-control-lg text-center" placeholder="Name" onChange={this.onUsernameChange} onKeyDown={this.detectEnterSubmit} />
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md={3} />
            <Col md={2} style={verticalAlign} className='text-center'>Profile Pic (optional):</Col>
            <Col md={4}>
              <Button variant="light"><input type="file" id="fileInput" accept=".png, .jpg, .jpeg, .gif" /></Button>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md={4} />
            <Col md={4}>
              <Button className="btn btn-block btn-lg btn-primary" onClick={this.onRegister} disabled={this.state.isCurrentlySubmitting ? ('disabled') : ('')}>Register!</Button>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md={3} />
            <Col md={6}>
              <Alert show={this.state.showAlert} variant={this.state.alertVariant}>{this.state.alertMessage}</Alert>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Register;
