import React from 'react';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet';

import Cookies from 'js-cookie';
import io from 'socket.io-client';

import * as constants from './Constants.js';

class CallNotificationPanel extends React.Component {
  constructor(props) {
    super(props);

    this.initCallSetupSocket = this.initCallSetupSocket.bind(this);
    this.onCallSetupDisconnect = this.onCallSetupDisconnect.bind(this);
    this.onReceiveCall = this.onReceiveCall.bind(this);
    this.removeIncomingCall = this.removeIncomingCall.bind(this);

    this.state = {
      callInitiators: {}
    }
  }

  componentDidMount() {
    this.callSetupSocket = null;
    this.initCallSetupSocket();
  }

  initCallSetupSocket() {
    if (this.props.attendeeId == null) {
      return;
    }

    const handShake = 'attendee_id=' + this.props.attendeeId;
    this.callSetupSocket = io(constants.API_ADDRESS + '/call_setup', {
      forceNew: true,
      query: handShake,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10
    });
    this.callSetupSocket.on('start_call', this.onReceiveCall);
    this.callSetupSocket.on('disconnect', this.onCallSetupDisconnect);
  }

  onCallSetupDisconnect(reason) {
    console.log('call setup disconnect');
    // if (reason === 'io server disconnect') {
    //   this.initCallSetupSocket();
    // }
  }

  onReceiveCall(data) {
    console.log('received call', data);
    var initiator = data.initiator;
    initiator['conversation_id'] = data.conversation_id;
    const calls = this.state.callInitiators;
    calls[data.conversation_id] = initiator;
    this.setState({callInitiators: calls});
    console.log('foo', this.state.callInitiators);
    setTimeout(() => this.removeIncomingCall(data.conversation_id), 15000)
  }

  removeIncomingCall(conversationId) {
    const calls = this.state.callInitiators;
    delete(calls[conversationId]);
    this.setState({callInitiators: calls});
  }

  render() {
    const callInitiators = [];
    for (const initiator of Object.values(this.state.callInitiators)) {
      callInitiators.push(
        <Card style={{padding: '5px'}}>
          <Card.Title>
            {initiator.name} is inviting you to a conversation.
          </Card.Title>
          <Row>
            <Col md={6}>
              <Button variant={'success'} size={'sm'} style={{width: '100%'}}
                  onClick={() => {this.setState({callInitiators: {}}); window.location.href = '/event/' + this.props.eventId + '/call/' + initiator.conversation_id}}>
                Accept
              </Button>
            </Col>
            <Col md={6}>
              <Button variant={'danger'} size={'sm'} style={{width: '100%'}}
                  onClick={(conversationId) => {this.removeIncomingCall(initiator.conversation_id)}}>
                Ignore
              </Button>
            </Col>
          </Row>
        </Card>
      );
    }

    const style = {
      position: 'fixed',
      'margin-left': '-250px',
      bottom: '10%',
      left: '50%',
      width: '500px'
    }
    return (
      <div style={style}>
        <Helmet>
          <title>{ 'EventRooms' } { Object.values(this.state.callInitiators).length != 0 ? ('(*)') : ('') }</title>
        </Helmet>
        {callInitiators}
      </div>
    );
  }
}

export default CallNotificationPanel;
