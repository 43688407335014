import React from 'react';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jitsi from 'react-jitsi'

import Cookies from 'js-cookie';
import io from 'socket.io-client';

import * as constants from './Constants.js';
import CallNotificationPanel from './CallNotificationPanel';

const util = require('./util.js');

class Call extends React.Component {
  constructor(props) {
    super(props);

    this.initSocket = this.initSocket.bind(this);
    this.onDisconnect = this.onDisconnect.bind(this);
    this.onApiLoad = this.onApiLoad.bind(this);
    this.onHangUp = this.onHangUp.bind(this);

    this.eventId = this.props.match.params.eventId;
    this.myId = Cookies.get(this.eventId + ':id')
    this.myName = Cookies.get(this.eventId + ':name');
    this.myPictureUrl = Cookies.get(this.eventId + ':picture');

    this.state = {
      myName: this.myName,
    };
  }

  componentDidMount() {
    this.clientSocket = this.initSocket(constants.API_ADDRESS + '/status',
      'event_id=' + this.eventId + '&id=' + this.myId + '&name=' + this.myName + '&image_url=' + encodeURIComponent(this.myPictureUrl) + '&status=CALL&conversation_id=' + this.props.match.params.conversationId);
    this.callClientSocket = this.initSocket(constants.API_ADDRESS + '/call_setup' +
      'attendee_id=' + this.myId);
  }

  initSocket(address, handShake) {
    console.log('init', address, handShake);
    const clientSocket = io(address, {
      forceNew: true,
      query: handShake
    });
    clientSocket.on('disconnect', (reason) => this.onDisconnect(reason, address, handShake));
    return clientSocket;
  }

  onDisconnect(reason, address, handShake) {
    if (reason === 'io server disconnect') {
      this.initSocket(address, handShake);
    }
  }

  onApiLoad(api) {
    console.log('api is ready');
    api.addEventListener('readyToClose', this.onHangUp);
  }

  onHangUp() {
    console.log('hung up!');
  }

  render() {
    return (
      <div style={{height: '67vh'}}>
        <Jitsi
            domain='jitsi.member.fsf.org'
            roomName={this.props.match.params.conversationId}
            displayName={this.state.myName}
            containerStyle={{width: '100%', height: '100%'}}
            config={{ disableDeepLinking: true, prejoinPageEnabled: false }}
            interfaceConfig={{ SHOW_JITSI_WATERMARK: false, SHOW_WATERMARK_FOR_GUESTS: false }}
            onAPILoad={this.onApiLoad} />
        <br/>
        <Button className='btn btn-lg btn-primary' onClick={() => window.location.href = '/event/' + this.eventId}>Back to Event</Button>
        <CallNotificationPanel attendeeId={this.myId} eventId={this.eventId} />
      </div>
    );
  }
}

export default Call;
