import React from 'react';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Cookies from 'js-cookie';
import io from 'socket.io-client';

import * as constants from './Constants.js';
import CallNotificationPanel from './CallNotificationPanel';

const util = require('./util.js');

class AttendeeDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.handleChecked = this.handleChecked.bind(this);
  }

  handleChecked() {
    if (this.props.myAttendeeId === this.props.attendee.id) {
      return;
    }
    this.props.onSelectChangeCallback(this.props.attendee.id);
  }

  render() {
    const verticalAlign = {
      'display': 'flex',
      'align-items': 'center'
    };
    return (
      <div style={{padding: '3px'}}>
        <div style={{cursor: 'pointer', padding: '10px'}} className={this.props.isSelected ? 'border border-success' : 'border border-white'}>
          <Row
              onClick={this.handleChecked}>
            <Col sm={3}><img src={this.props.attendee.image_url} width={75} height={75} /></Col>
            <Col sm={7} style={verticalAlign}>{this.props.attendee.name}</Col>
            <Col sm={2} style={verticalAlign}>
              {this.props.myAttendeeId === this.props.attendee.id ? 'SELF' : ''}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

class Conversation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const attendees = [];
    for (const attendee of this.props.attendees) {
      attendees.push(
        <AttendeeDisplay
            myAttendeeId={this.props.myAttendeeId}
            attendee={attendee}
            isSelected={this.props.selectedForConversation.has(attendee.id)}
            onSelectChangeCallback={this.props.onSelectChangeCallback}
            />);
    }
    return (
      <div>
        {attendees}
        <br/>
        <Button className='btn btn-primary' onClick={() => window.location.href = '/event/' + this.props.eventId + '/call/' + this.props.conversationId}>Join Conversation</Button>
      </div>
    );
  }
}

class Lobby extends React.Component {
  constructor(props) {
    super(props);

    this.initStateSocket = this.initStateSocket.bind(this);
    this.onDisconnect = this.onDisconnect.bind(this);
    this.initCallSetupSocket = this.initCallSetupSocket.bind(this);
    this.onCallSetupDisconnect = this.onCallSetupDisconnect.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.startConversationWithSelected = this.startConversationWithSelected.bind(this);

    this.eventId = this.props.match.params.eventId;
    this.myAccessToken = Cookies.get(this.eventId + ':accessToken');
    this.myId = Cookies.get(this.eventId + ':id')
    this.myName = Cookies.get(this.eventId + ':name');

    this.myPictureUrl = Cookies.get(this.eventId + ':picture');

    if (this.myId == null) {
      window.location.href = '/register/' + this.eventId;
    }

    this.state = {
      isCurrentlyStarting: false,
      eventName: '',
      selectedForConversation: new Set(),
      conversations: [],
      nonConversingAttendees: []
    };

    fetch(constants.API_ADDRESS + '/event?id=' + this.eventId, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
        this.setState({eventName: response.event.name});
      }
    });
  }

  componentDidMount() {
    this.setState({selectedForConversation: new Set()});
    this.clientSocket = null;
    this.initStateSocket();
    this.callSetupSocket = null;
    this.initCallSetupSocket();
  }

  initStateSocket() {
    if (this.eventId == null || this.myId == null || this.myName == null) {
      return;
    }
    const handShake = 'event_id=' + this.eventId + '&id=' + this.myId + '&name=' + this.myName + '&image_url=' + encodeURIComponent(this.myPictureUrl) + '&status=IDLE';
    this.clientSocket = io(constants.API_ADDRESS + '/status', {
      forceNew: true,
      query: handShake,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10
    });
    this.clientSocket.on('disconnect', this.onDisconnect);
    this.clientSocket.on('update_state', this.updateState);
  }

  onDisconnect(reason) {
    console.log('disconnected!');
    // if (reason === 'io server disconnect') {
    //   this.initStateSocket();
    // }
  }

  initCallSetupSocket() {
    if (this.myId == null) {
      return;
    }

    const handShake = 'attendee_id=' + this.myId;
    this.callSetupSocket = io(constants.API_ADDRESS + '/call_setup', {
      forceNew: true,
      query: handShake,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10
    });
    this.callSetupSocket.on('disconnect', this.onCallSetupDisconnect);
  }

  onCallSetupDisconnect(reason) {
    console.log('call setup disconnect');
    // if (reason === 'io server disconnect') {
    //   this.initCallSetupSocket();
    // }
  }

  updateState(data) {
    console.log('state', data);
    this.setState({
      conversations: data.conversations,
      nonConversingAttendees: data.non_conversing_attendees
    });
  }

  onSelectChange(attendeeId) {
    const selectedForConversation = this.state.selectedForConversation;
    if (selectedForConversation.has(attendeeId)) {
      selectedForConversation.delete(attendeeId);
    } else {
      selectedForConversation.add(attendeeId);
    }
    this.setState({selectedForConversation: selectedForConversation});
  }

  startConversationWithSelected() {
    console.log('started');
    this.setState({isCurrentlyStarting: true});
    fetch(constants.API_ADDRESS + '/conversation', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({})
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      this.setState({isCurrentlyStarting: false});
      if (response.status == 'ok') {
        console.log('going in');
        this.callSetupSocket.emit('start_call', {
          event_id: this.eventId,
          conversation_id: response.conversation_id,
          initiator: {
            name: this.myName,
            id: this.myId,
            image_url: this.myPictureUrl
          },
          recipient_ids: Array.from(this.state.selectedForConversation)
        });
        this.setState({selectedForConversation: new Set()});
        window.location.href = '/event/' + this.eventId + '/call/' + response.conversation_id;
      }
    });
  }

  render() {
    const conversations = [];
    for (const conversation of this.state.conversations) {
      conversations.push(
        <div style={{padding: '10px'}} className='border'>
          <Conversation
              myAttendeeId={this.myId}
              eventId={this.eventId}
              conversationId={conversation.id}
              attendees={conversation.attendees}
              selectedForConversation={this.state.selectedForConversation}
              onSelectChangeCallback={this.onSelectChange}/>
        </div>);
      conversations.push(<br/>);
    }

    const nonConversingAttendees = [];
    for (const attendee of this.state.nonConversingAttendees) {
      nonConversingAttendees.push(
        <div>
        <AttendeeDisplay
            myAttendeeId={this.myId}
            attendee={attendee}
            isSelected={this.state.selectedForConversation.has(attendee.id)}
            onSelectChangeCallback={this.onSelectChange}
            /></div>);
    }

    const verticalAlign = {
      'display': 'flex',
      'align-items': 'center'
    };

    return (
      <div>
        <h1>{this.state.eventName}</h1>
        <br/>
        <Row>
          <Col style={verticalAlign}>
            <Button
                className={'btn btn-primary'} onClick={this.startConversationWithSelected} disabled={this.state.selectedForConversation.size == 0 ? 'disabled' : ''}>
              Start New Conversation...
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            Select attendees to strike up a conversation!
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={6}>
            <h2>On-Going Conversations</h2>
            <br/>
            {conversations}
          </Col>
          <Col md={6}>
            <h2>Non-Conversing Attendees</h2>
            <br/>
            <div style={{padding: '5px'}} className='border'>{nonConversingAttendees}</div>
          </Col>
        </Row>

        <CallNotificationPanel attendeeId={this.myId} eventId={this.eventId} />
      </div>
    );
  }
}

export default Lobby;
