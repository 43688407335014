import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import {FixedBottom} from 'react-fixed-bottom';

import Container from 'react-bootstrap/Container';

import Call from './Call.js';
import Home from './Home.js';
import Lobby from './Lobby.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import Register from './Register.js';
import TermsOfService from './TermsOfService.js';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const footerStyle = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    width: "100%",
  }
  return (
    <div>
    <Router>
      <Container>
        <div id="app" style={{height:'100%'}}>
        <Navbar style={{'border-bottom':'1px solid #D3D3D3'}} expand="sm">
          <Nav>
            <Nav.Link href='/'><b>EventRooms</b></Nav.Link>
          </Nav>
        </Navbar>
        <br/>
        <Switch>
          <Route path="/privacy_policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms_of_service">
            <TermsOfService />
          </Route>
        </Switch>
        <Switch>
         <Route path="/register/:eventId" component={Register} />
        </Switch>
        <Switch>
         <Route path="/event/:eventId/call/:conversationId" component={Call} exact={true} />
        </Switch>
        <Switch>
         <Route path="/event/:eventId" component={Lobby} exact={true} />
        </Switch>
        <Switch>
         <Route path="/" exact component={Home} />
        </Switch>
        <br/><br/><br/><br/><br/>
        </div>
      </Container>
    </Router>
    <FixedBottom offset={0}>
      <div style={footerStyle}>
        By using this website, you agree to our <a href='/privacy_policy'>Privacy Policy</a> and <a href='/terms_of_service'>Terms of Service</a>. Learn more about us on our <a href='/'>Homepage</a>!
      </div>
    </FixedBottom>
    </div>
  );
}

export default App;
