import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import * as constants from './Constants.js';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.eventName = '';

    this.state = {
      isCurrentlySubmitting: false
    };

    this.onEventNameChange = this.onEventNameChange.bind(this);
    this.detectEnterSubmit = this.detectEnterSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onEventNameChange(event) {
    this.eventName = event.target.value;
  }

  detectEnterSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onSubmit();
    }
  }

  onSubmit() {
    console.log(this.eventName);
    this.setState({isCurrentlySubmitting: true});
    fetch(constants.API_ADDRESS + '/event', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({
        'event_name': this.eventName
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      this.setState({isCurrentlySubmitting: false});
      if (response.status == 'ok') {
        window.location.href = '/event/' + response.event_id;
      }
    });
  }

  render() {
    return (
      <div>
      <br/><br/><br/>
      <Row>
        <Col md={12}>
          <h1 class='text-center'>Create Virtual Social Events!</h1>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col md={12}>
          <p class='text-center'>
            Host events via video chat. Start or join video conversations with
            others spontaneously as if you were in-person.
          </p>
          <p class='text-center'>
            Great for conferences, networking, and parties in a stay-at-home world!
          </p>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col md={2} />
        <Col md={8}>
          <form>
            <div class="form-row">
              <div class="col-12 col-md-9 mb-2 mb-md-0">
                <input type="text" class="form-control form-control-lg" placeholder="Enter your event name..." onChange={this.onEventNameChange} onKeyDown={this.detectEnterSubmit} />
              </div>
              <div class="col-12 col-md-3">
                <Button className="btn btn-block btn-lg btn-primary" onClick={this.onSubmit} disabled={this.state.isCurrentlySubmitting ? ('disabled') : ('')}>Go!</Button>
              </div>
            </div>
          </form>
        </Col>
      </Row>
      <br/><br/><br/>
      <Row>
        <Col md={4} className='text-center'>
          <Row><Col md={12}><img src={'./cursor.svg'} width={50} height={50} /></Col></Row>
          <br/>
          <Row><Col md={12}>Share the event link with participants.</Col></Row>
        </Col>
        <Col md={4} className='text-center'>
          <Row><Col md={12}><img src={'./handshake.svg'} width={50} height={50} /></Col></Row>
          <br/>
          <Row><Col md={12}>Participants can join existing conversations or start new ones.</Col></Row>
        </Col>
        <Col md={4} className='text-center'>
          <Row><Col md={12}><img src={'./mic.svg'} width={50} height={50} /></Col></Row>
          <br/>
          <Row><Col md={12}>Chat on!</Col></Row>
        </Col>
      </Row>
      </div>
    );
  }
}

export default Home;
